<template>
    <div>
        <ValidationObserver ref="detailFormValid">
            <b-row>
                <b-col cols="12">
                    <h6> {{ innerValue.name }} {{ innerValue.surname }} </h6>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="academic_year" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox :disabled="disabled" :validate-error="errors[0]"
                                v-model="innerValue.academic_year">
                            </academic-years-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="cgpa" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('cgpa')">
                            <b-form-input type="text" :disabled="disabled" v-model="innerValue.cgpa">
                            </b-form-input>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <ValidationProvider name="cgpa" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('class')">
                            <classes-selectbox v-model="innerValue.class" :disabled="disabled"
                                :validate-error="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('faculty_name')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(getLocaleText(innerValue,'faculty_name')) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('program_name')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(getLocaleText(innerValue,'program_name')) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('semester_count')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(innerValue.semester_count) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('prep_status')">
                        <b-input-group>
                            <div class="label-as-input">{{ getLocaleText(innerValue, 'prep_status_text') }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('registration_type')">
                        <b-input-group>
                            <div class="label-as-input">{{ getLocaleText(innerValue, 'registration_type_text') }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4">
                    <b-form-group :label="$t('student_number')">
                        <b-input-group>
                            <div class="label-as-input">{{ isNotNullValue(innerValue.student_number) }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="4" v-if="innerValue.student_program.student.person.nationality_code != 'TR'">
                    <b-form-group :label="$t('equivalence_status')">
                        <b-input-group>
                            <div class="label-as-input">{{ innerValue.equivalence_status ? $t('yes') : $t('no') }}</div>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <p>{{ $t('documents') }}</p>
                    <div class="d-flex flex-row mb-5">
                        <button class="btn btn-primary" :disabled="documentLoading"
                            @click="downloadDocument('student_transcript')">
                            <loading :show="documentLoading" />
                            {{ $t('transcript') }}
                        </button>
                        <button class="btn btn-primary ml-3" :disabled="documentLoading"
                            @click="downloadDocument('student_english_language_proficiency_document')">
                            <loading :show="documentLoading" />
                            {{ $t('language_proficiency_certificate') }}
                        </button>
                    </div>
                </b-col>
            </b-row>
        </ValidationObserver>
        <div class="font-weight-medium mb-2">
            {{ $t("student_preferences").toUpper() }}
        </div>
        <b-table :empty-filtered-text="$t('no_result')" :empty-text="$t('no_result')" bordered
            :items="innerValue.minor_application_preferences" :fields="minorApplicationPreferenceFields" show-empty
            class="mb-4 table-dropdown no-scrollbar border rounded">

            <template #cell(rank)="row">
                <div class="d-flex align-items-center justify-content-around rank-container">
                    {{ row.item.rank }}
                </div>
            </template>
            <template #cell(faculty_program)="row">
                {{ row.item[getLocaleField('faculty_name')] }} <br />
                {{ row.item[getLocaleField('program_name')] }}
            </template>
            <template #cell(prep_status)="row">
                <div class="text-center">
                    <template v-if="row.item.prep_status == null">
                        {{ $t('exempt') }}
                    </template>
                    <template v-if="row.item.prep_status == 1">
                        {{ $t('success') }}
                    </template>
                    <template v-if="row.item.prep_status == 0">
                        {{ $t('unsuccessful') }}
                    </template>
                </div>
            </template>
            <template #cell(result)="row">
                <template v-if="!row.item.result">-</template>
                <template v-else>
                    <span class="badge" :class="['placed', 'other_preference_placed'].includes(row.item.result) ? 'badge-success'
                        : row.item.result == 'not_placed' ? 'badge-danger' : 'badge-warning'">
                        {{ $t(`application_result.${row.item.result}`) }}
                    </span>
                </template>
            </template>
            <template #cell(status)="row">
                <div class="d-flex justify-content-center">
                    {{ row.item.status_text }}
                </div>

            </template>
            <template #cell(action)="row">
                <!-- <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <i class="ri-more-fill"></i>
                    </template>
                    <b-dropdown-item @click="updateApplication(row.item)">{{ $t('status_update') }}</b-dropdown-item>
                </b-dropdown> -->
                <div class="d-flex justify-content-center">
                    <button class="btn btn-primary float-center" @click="updateApplication(row.item)"> {{
                        $t('status_update')
                    }}</button>
                </div>
            </template>
            <template #cell(explanation)="row">
                {{ row.item.explanation || "-" }}
            </template>
        </b-table>
        <CommonModal ref="updateStatusFormModal" size="md" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t("application_status_update").toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <ValidationObserver ref="updateFormValid">
                    <b-row>
                        <b-col cols="12">
                            <ValidationProvider name="status" rules="required" v-slot="{ errors }">
                                <b-form-group :label="$t('status')">
                                    <b-form-select :validate-error="errors[0]" v-model="update.status"
                                        :state="errors[0] ? false : null"
                                        :options="[{ text: $t('declined'), value: 'declined' }, { text: $t('status_approved'), value: 'approved' }]">
                                    </b-form-select>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" v-show="update.status === 'declined'">
                            <ValidationProvider name="explanation"
                                :rules="`${update.status === 'declined' ? 'required' : ''}`" v-slot="{ errors }">
                                <b-form-group :label="$t('explanation')">
                                    <b-form-input :validate-error="errors[0]" v-model="update.explanation"
                                        :state="errors[0] ? false : null">
                                    </b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12">
                            <button class="btn btn-primary float-right" @click="updateStatus">
                                {{ $t("save") }}
                            </button>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </template>
        </CommonModal>
    </div>
</template>

<script>
import MinorApplicationService from "@/services/MinorApplicationService";
import StudentDocumentService from "@/services/StudentDocumentService";

import { EventBus } from '@/main'

export default {
    name: "MinorApplicationAdminForm",
    props: {
        minorApplication: {
            type: Object,
        },
        type: {
            type: String,
            default: "",
        },
        value: {
            type: Object,
            default: () => { },
        },
    },
    computed: {
        innerValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
        rankOptions() {
            return this.innerValue.minor_application_preferences.map(item => ({ text: item.rank, value: item.rank }))
        },
        disabled() {
            return ["show", "delete"].includes(this.type)
        }
    },

    data() {
        return {
            note: {},
            formLoading: false,
            updateRank: { rank: null },
            oldRank: null,
            update: {
                minor_application_id: -1,
                status: null,
            },
            documentLoading: false,
            minorApplicationId: null,
            minorApplicationPreferenceFields: [
                {
                    key: "rank",
                    label: this.$t("rank").toUpper(),
                    tdClass: "text-center align-middle",
                    thClass: "text-center",
                    sortable: false,
                },
                {
                    key: "faculty_program",
                    label: this.$t("faculty").toUpper(),
                    tdClass: "align-middle",
                    sortable: false,
                },
                {
                    key: "prep_status",
                    label: this.$t("prep_status").toUpper(),
                    tdClass: "align-middle align-center",
                    thClass: "text-center",
                    sortable: false,
                },
                {
                    key: "result",
                    label: this.$t("result").toUpper(),
                    tdClass: "align-middle",
                },
                {
                    key: this.getLocaleField("status_text"),
                    label: this.$t("status").toUpper(),
                    tdClass: "align-middle align-center",
                    thClass: "text-center",
                    sortable: false,
                },
                {
                    key: "action",
                    label: this.$t("action").toUpper(),
                    tdClass: "text-center align-center",
                    thClass: "text-center",
                    sortable: false,
                },
                {
                    label: this.$t("explanation").toUpper(),
                    key: "explanation",
                },
            ],

            extraInfo: false,
        };
    },
    methods: {
        async updateApplication(row) {
            this.$refs.updateStatusFormModal.$refs.commonModal.show()
            this.update.minor_application_preference_id = row.id
            this.update.minor_application_id = this.innerValue.id
            this.update.status = ""
        },
        async updateStatus() {
            const valid = await this.$refs['updateFormValid'].validate()

            if (!valid) {
                return
            }

            if (this.update.status === 'approved') {
                delete this.update.explanation
            }
            const response = await MinorApplicationService.academicUnitOfficeApprove(this.update)
                .catch(e => this.$toast.error(this.$t('api.' + e.data.message)))


            if (response.data.success) {
                this.$refs.updateStatusFormModal.$refs.commonModal.hide()

                this.$emit('getItem', this.innerValue.id)
                this.update = {}
            }
            this.$toast.success(this.$t('api.' + response.data.message));
        },
        downloadDocument(name) {
            this.documentLoading = true
            if (name === "student_transcript") {
                StudentDocumentService.downloadTranscript(this.innerValue.student_program_id, this.$i18n.locale)
                    .then(response => {
                        let fileName = this.innerValue.student_program_id + '-' + this.$t('transcript') + '-' + this.$i18n.locale + '.pdf'
                        EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
                    }).finally(() => {
                        this.documentLoading = false
                    })
            } else if (name === "student_english_language_proficiency_document") {
                StudentDocumentService.downloadLanguageProficiency(this.innerValue.student_program_id, this.$i18n.locale)
                    .then(response => {
                        let fileName = this.innerValue.student_program_id + '-' + this.$t('file_name_language_proficiency') + '-' + this.$i18n.locale + '.pdf'
                        EventBus.$emit('pdfViewerModalShow', { pdfFileData: response.data, pdfFileName: fileName });
                    })
                    .catch(e => {
                        this.showErrors(e, null, true)
                    }).finally(() => {
                        this.documentLoading = false
                    })
            }

        },

    },
};
</script>

<style lang="scss">
.rank-container {
    i {
        width: 24px;
        height: 24px;

        &::before {
            width: 40px;
            height: 40px;

            width: 24px;
            height: 24px;
        }
    }
}
</style>
